import "./bootstrap";
import "../css/tailwind.css";
import "../css/style.css";
import i18n from "@/Lang/LangConfig.js";
import { CkeditorPlugin } from "@ckeditor/ckeditor5-vue";
import { createInertiaApp, Link } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import Card from "primevue/card";
import PrimeVue from "primevue/config";
import Ripple from "primevue/ripple";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import { createApp, h } from "vue";
import { ZiggyVue } from "../../vendor/tightenco/ziggy";

const appName = import.meta.env.VITE_APP_NAME || "Laravel";

const app = createInertiaApp({
    title: (title) =>
        title && title.length > 0 ? `${title} - ${appName}` : `${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue"),
        ),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(PrimeVue, {
                theme: "none",
                ripple: true,
            })
            .use(i18n)
            .use(ZiggyVue)
            .use(CkeditorPlugin)
            .component("Link", Link)
            .component("Card", Card)

            .use(ToastService)
            .directive("ripple", Ripple)
            .directive("tooltip", Tooltip)
            .mount(el);
    },
    progress: {
        color: "#4B5563",
    },
});
